import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp, FirebaseApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6DQr1Ein9LcCSihIal3A30LIhqNK39Wg",
  authDomain: "stubblogs.firebaseapp.com",
  projectId: "stubblogs",
  storageBucket: "stubblogs.appspot.com",
  messagingSenderId: "129248952509",
  appId: "1:129248952509:web:735f24aaeae5e299aa37d4",
  measurementId: "G-97HHXEZVZ8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
var urlParams = new URL(window.location.href).searchParams;
var root = ReactDOM.createRoot(document.getElementById('root'));
window.onpopstate = function(event) {
  window.location.href = document.location;
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('page');
  if(myParam.get("page") === "login") {
    document.title = "StubBlogs | Login";
  showLogin();
  }else{
    home();
  }
};
if(urlParams.get("page") === "login") {
  document.title = "StubBlogs | Login";
  showLogin();
}else{
  home();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function home() {
  root.render(
    <React.StrictMode>
      <div style={{display:"table", padding:"10px", backgroundColor:"white", borderBottomColor:"white", borderBottomStyle:"solid", borderBottomWidth:"2px", color:"black"}} id="container">
         <div id="insider" style={{display:"flex"}}>
         <img src="./icon.png" width={48} height={48} style={{borderRadius:"360px", display:"table-cell", verticalAlign:"middle", transform:"translateY(26%)"}}></img>
         <h1 style={{marginLeft:"10px"}}>StubBlogs</h1>
         </div>
      </div>
      <h1 style={{marginLeft:"8px",}}>Create awesome blogs on the go with StubBlogs</h1>
      <p style={{marginLeft:"8px", fontSize:"22px",}}>StubBlogs is very fast and makes your Blogs look attractive</p>
      <form onSubmit={function(e) {
           e.preventDefault();
           window.history.pushState(document.documentElement.innerHTML, "", "?page=login");
           showLogin();
      }}>
      <button type='submit' style={{marginLeft:"8px", fontSize:"22px", }}>Create a Blog</button>
      </form>
    </React.StrictMode>
  );
}
reportWebVitals();
function showLogin() {
  document.title = "StubBlogs | Login";
  root.render(
    <React.StrictMode>
    <div style={{display:"table", padding:"10px", backgroundColor:"white", borderBottomColor:"white", borderBottomStyle:"solid", borderBottomWidth:"2px", color:"black"}} id="container">
       <div id="insider" style={{display:"flex"}}>
       <img src="./icon.png" width={48} height={48} style={{borderRadius:"360px", display:"table-cell", verticalAlign:"middle", transform:"translateY(26%)"}}></img>
       <h1 style={{marginLeft:"10px"}}>StubBlogs Login</h1>
       </div>
    </div>
    <div>
    <p style={{marginLeft:"8px", fontSize:"22px",}}>Please login with your StubBlogs E-Mail address and password to continue</p>
       <form onSubmit={function(e){
        e.preventDefault();
        const mainButton = document.getElementById("mainButton");
        const emailText = document.getElementById("emailText").value;
        const passText = document.getElementById("passText").value;
        const mainStylePrev = mainButton.style;
        mainButton.style.transition = "0.3s";
        mainButton.style.borderRadius = "360px";
        mainButton.style.transition = "0.3s";
        mainButton.style.backgroundColor = "grey";
        mainButton.style.transition = "0.3s";
        mainButton.innerHTML = "Please Wait...";
        mainButton.style.transition = "0.3s";
        if(passText.length <= 11) {
           alert("Invalid password length! Please enter a password atleast of 12 characters!");
           mainButton.style.transition = "0.3s";
           mainButton.style.backgroundColor = "black";
           mainButton.style.transition = "0.3s";
           mainButton.innerHTML = "Try Again";
           mainButton.style.transition = "0.3s";
           mainButton.style.borderRadius = "5px";
           mainButton.style.transition = "0.3s";
        }else{
            continueNext();
            async function continueNext() {
              const auth = getAuth();
              signInWithEmailAndPassword(auth, emailText, passText)
                .then((userCredential) => {
                  mainButton.innerHTML = "Almost done! Please wait...";
                  mainButton.style.transition = "0.3s";
                  mainButton.style.width = "14em";
                  mainButton.style.transition = "0.3s";
                  const user = userCredential.user;
                  const email = user.email;
                  const uid = user.uid;
                  alert("Welcome! You are signed in as " + email);
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  try {
                     
                  }catch(error) {
                      
                  }
                });
            }
        }
       }}>
        <input id='emailText' placeholder='StubBlogs E-Mail address' required></input>
        <br></br>
        <input id='passText' placeholder='StubBlogs Password' style={{marginTop: "15px"}} required></input>
        <br></br>
        <br></br>
        <button id='mainButton' type='submit' style={{marginLeft:"8px", fontSize:"22px",}} required>Next</button>
        </form> 
    </div>
  </React.StrictMode>
   );
}